import {FETCH_LOGIN, FETCH_OTP, FETCH_REGISTER,IS_AUTHENTICATED} from '../reducers/types/authTypes';
import axios from 'axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jsonwebtoken'
import { trackPromise } from 'react-promise-tracker'
import { confirmAlert } from 'react-confirm-alert'; // Import
import React, { useEffect, useState } from "react";
import qs from 'qs';
    
const apiUrl = 'https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api'
const apiUrl_backup = 'https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api'
//apiurl = "https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api"



function wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
  }
  

  axios.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes("timeout") || message.includes("Network Error"))) {
  //    toast.error("err 2")
   //   return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        toast.error("Please wait ....")
        console.log("retry the request", config.url);
        resolve();
      }, config.retryDelay || 6000);
    });
    return delayRetryRequest.then(() => axios(config));
  });
  

  
  export default function setAuthorizationToken (token){

    if(token){
        return {headers:{
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }}
    }
    else {
        return {headers:{
            'Authorization': `Bearer`,
            'Content-Type': 'application/json'
        }}
    }
}


export function setIsAuthenticated(user){
    return {
        type: IS_AUTHENTICATED,
        payload: user
    }
}
 
const config = setAuthorizationToken("MiA4MzA0In0.Lbs5QaXwinnwsKwpfy3APkO3poUjBljVOOcIk6-XUIQTEST")




const Get = (user)  => {
    const [users, setUsers] = useState([]);
    var  shopname = sessionStorage.getItem('shop')
    const {username, password} = user

}

export const logiget = (user) => async dispatch =>{

    

    const paydone = localStorage.removeItem('paydone')

  
    var  shopname = sessionStorage.getItem('shop')
    const {username, password} = user
 

    
    try{
        
        
        var mycount
        mycount = 0
    
        var res

        var i;
        for (i = 0; i < 5; i++) {
            toast.success('Message : Login. Sending OTP.');
    
            var s1 
            s1  = "https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/dra/glogin?username=dra1&password=4331";

            s1  = "https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/dra/glogin?username="+username+"&password="+password;

         
            
            res = await trackPromise(axios.post(`${apiUrl_backup}/auth/login`, {username, password,shopname}, config))
            res = await trackPromise(axios.post(`${apiUrl_backup}/auth/login`, {username, password,shopname}, config))

            console.log(res.data, 'login')
            if(res) break;
            if (mycount > 3) 
                if(!res) break;
            mycount = mycount + 1 
            
        }

            if(res){

          
                dispatch({
                    type: FETCH_LOGIN,
                    payload: res.data
                 });

                 return "200OK";

                
            }
       
        } catch (error){

          //  toast.error("Internal Server Error");
          //  toast.error(error);


            try{
        
        
                var mycount
                mycount = 0
            
                var res1
        
                var i;
                for (i = 0; i < 5; i++) {
                    toast.success('Logging ... ')
        
                 //   res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { data , auth , order_id, gateway_fee, subtotal_price, total_price, username,shopname}))
                    res1 = await trackPromise(axios.post(`${apiUrl_backup}/dra/plogin`, {username, password,shopname}, config))
        
                    console.log(res.data, 'login')
                    if(res1) break;
                    if (mycount > 3) 
                        if(!res1) break;
                    mycount = mycount + 1 
                    
                }
        
                    if(res1){
        
                  
               
                        dispatch({
                            type: FETCH_LOGIN,
                            payload: res.data
                         });
                        
                         return "200OK";

                    }
               
                } catch (error){
        
                 //   toast.error("Internal Server Error");

                }
        

        }


        var start = new Date().getTime();
        localStorage.setItem('date1',start)
        const done = localStorage.getItem('date1')
    
  toast.error("Checking Server ... ");

    
  res = await trackPromise(axios.get(s1, {username, password,shopname}))
  //           res = await trackPromise(axios.post(`${apiUrl_backup}/auth/login`, {username, password,shopname}, config))
      //        res = await trackPromise(axios.post(`https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/auth/login`, {username, password,shopname}, config, {timeout : 5}))
              
              console.log(res.data, 'login')
              
          
  
              if(res){
  
            
                  dispatch({
                      type: FETCH_LOGIN,
                      payload: res.data
                   });
  
                   return "200OK";
  
                  
              }

wait(5000)


axios.get(s1, {
  timeout: 5000, // Set a timeout of 5 seconds
  headers: {
    'X-RapidAPI-Key': 'your-rapid-api-key',
    'X-RapidAPI-Host': 'jokes-by-api-ninjas.p.rapidapi.com'
  }
})
.then(response => {
  console.log(response.data);
  dispatch({
    type: FETCH_LOGIN,
    payload: response.data
 });

 return "200OK";


})
.catch(error => {
  if (error.code === 'ECONNABORTED') {
    console.log('Request timed out');
  } else {
    console.log(error.message);
  }
});




   

      const response1= await fetch(s1);
      const data1 = await response1.json();
      toast.error(data1);

}






export const login = (user) => async dispatch =>{

    

    
    const {username, password} = user
    
    var  shopname = sessionStorage.getItem('shop')
    var mycount
    mycount = 0

    var res
    
      
      var i;



      var s1 
      s1  = "https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/dra/glogin?username=dra1&password=4331";

    
      
      var mypassword = encodeURIComponent(password);
      var myshopname = encodeURIComponent(shopname);
      var myusername = encodeURIComponent(username);

      localStorage.setItem('username',username)

      s1  = "https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/dra/glogin?username="+myusername+"&password="+mypassword+"&shopname="+myshopname;

   
      var test = 'y';
      
      try {
      for (i = 0; i < 5; i++) {
        //  toast.success('Server...')
          res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000,  username,  test}))
          
          if(res.data.message === "testing : ") 
            {
                toast.error('Please wait - Start')
                break;
            }    
          
          
      }
      } catch (error){}   

      try {
      } catch (error){

        res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000,  username,  test}))
      }

        
  res = await trackPromise(axios.get(s1, {username, password,shopname}))
  //           res = await trackPromise(axios.post(`${apiUrl_backup}/auth/login`, {username, password,shopname}, config))
      //        res = await trackPromise(axios.post(`https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/auth/login`, {username, password,shopname}, config, {timeout : 5}))
              
              console.log(res.data, 'login')
              
          
  
              if(res){
  
           //     toast.success("Correct");          
          
                  dispatch({
                      type: FETCH_LOGIN,
                      payload: res.data
                   });
  
                   return "200OK";
  
                  
              }


        var start = new Date().getTime();
        localStorage.setItem('date1',start)
        const done = localStorage.getItem('date1')
  //
        //toast.error(done);
  
   

    
      
    
}


function hasWhiteSpace(s) {
    return s.indexOf(' ') >= 0;
  }
function has65(s) {
    return s.indexOf('+65') === 0;
}
function hasT(s) {
    return ( (s.indexOf('T') === 0) || (s.indexOf('t') === 0));
  }
function hasS(s) {
    return ( (s.indexOf('S')) === 0 || (s.indexOf('s') === 0));
  }


function lastcharisLetter(str) {
  const last = str.charAt(str.length - 1);
    return last.match(/[a-z]/i);
}

export const register =  (user) => async dispatch =>{

    const {username, password,password_confirmation,sms_handphone,email,alias_username,ictype} = user
    //    //toast.error(ictype);
    //    //toast.error(username);
    if (hasWhiteSpace(email))
    {
            toast.error('cannot have space in email');
            return false
        
    }    
    if (hasWhiteSpace(username))
    {
            toast.error('cannot have space in username');
            return false
        
    }
    if (hasWhiteSpace(alias_username))
    {
            toast.error('cannot have space in ic');
            return false
        
    }
    if (hasS(alias_username))
    {
          toast.error('S is not required in ic');
            return false
         
        
    }
    if (hasT(alias_username))
    {
       
            toast.error('T is not required in ic');
            return false
        
    }
    if (hasWhiteSpace(password))
    {
            toast.error('cannot have space in password');
            return false
        
    }
    if (hasWhiteSpace(sms_handphone))
    {
            toast.error('cannot have space in tel no');
            return false
        
    }
    if (has65(sms_handphone))
    {
      if (sms_handphone.length!==11)
      {
        toast.error('incorrect length in tel no');
        return false
    
      }
  
    }
    else
    {
            toast.error('must be singapore tel no, add +65');
            return false
        
    }
    
    


    if (ictype==1)
    if (lastcharisLetter(alias_username))
    {
      if (alias_username.length!==8)
      {
        toast.error('incorrect length in NRIC');
        return false
    
      }
  
    }
    else
    {
      toast.error('last aphabet is required');
      return false
    }

        try{
           const res = await trackPromise(axios.post(`${apiUrl}/auth/register`, {username, password,password_confirmation,sms_handphone,email,alias_username,ictype}, config))
           if(res.data.error){
            //toast.error(res.data.error);
            
            toast.error('Pls use another username.');
            return false
           }
           else{
      //      //toast.error('NO ERROR');
         
     /*       dispatch({
                type: FETCH_REGISTER,
                payload: res.data
             });
    */
    
    confirmAlert({
        title: 'Registration',
        message: 'Account activation within 24hrs.',
        buttons: [
    //      {
     //       label: 'Logout',
      //      onClick: () => dispatch(logout())
       //   },
          {
            label: 'OK',
            onClick: () => dispatch(logout())
          }
        ]
      });
    }
    
           
    
           
            } catch (error){
    
                ////toast.error(error);
                if (error.response.status === 401) {
                    //place your reentry code
                   // //toast.error("Username or alias name are taken.");
                //   //toast.error('ERROR');
          
                    //toast.error(error.response.data.error);
                   }
                //   else
                   //toast.error("server error.");
                
                
            }
    
    
    }
    

  export const logout =  () => async dispatch =>{

       
    
  try {  
    const done1 = localStorage.getItem('date1')
  //  //toast.error(done1);
    var now = new Date().getTime();
    var duration = now - done1
    var dur1 =  duration / 10000
    
  //  //toast.error(dur1);
    }

     catch (error){
        
    // //toast.error("Internal Server Error");
    }


   // toast.success('logout');

    var jwt
    var done
    var iflogin
    var imessage

    jwt = sessionStorage.getItem("jwtToken");
    done = sessionStorage.getItem("logout");
    iflogin = localStorage.getItem("mylogin");
    imessage = localStorage.getItem("mymessage");


   

  //   toast.success(iflogin);
    
  //'  toast.success('Message : '+iflogin);
   

        if (jwt!=null)
            {
   
        
   
   
                if (iflogin=='done')
                {
             //   toast.success('Message : Logout');
                localStorage.removeItem("jwtToken")  
                sessionStorage.setItem('Logout', 'done');
                }
            
            }

            if (dur1 < 100)
            { 

    }
   

    
    // logout - regardless server status

  

    localStorage.removeItem("mylogin")  
                

  var shop = sessionStorage.getItem('shop')  
  var shopname=""
 
    shopname = 'Session Expired'
    window.location.replace('/')
  

  
//    try{
     //  const res = await trackPromise(axios.get(`${apiUrl}/auth/logout`, config))
    //   if(!res.data){
     //   //toast.error(res.data.error);
     //   return false
     //  }
       ///else if (res.data.message == 'Successfully logged out')
  //     {

     //   const removeLocalStorage = localStorage.removeItem("jwtToken");
    //    window.location.replace('/login')

       
    //   }

       
      //  } catch (error){


        //    if(error.message == "Token has Expired"){
         //       window.location.replace("login")
          //  }
          //  //toast.error("Internal Server Error");
      //  }


}



export const mypaynow =  () => {


    
    window.location.replace('/p')



}


export const paycallback =  () => {
   

    var shop = sessionStorage.getItem('shop')  
    toast.success('Paynowcallback')
    window.location.replace('/paynowcallback')
  
    }

    

export const verifyOtp = (form) => async dispatch =>{

    const {contact_number, code} = form
    const paydone = localStorage.removeItem('paydone')

    const data = { 'code': code, 'contact_number' : contact_number };
    const url = 'https://dranixmyapp1.herokuapp.com/online-pdms-cashbox/public/api/auth/verify-user'
    const options = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      
    };

    var username = sessionStorage.getItem('username')  
    
 //   toast.success("Verffying OTP");
 //   wait(3000)
           
    try{

    

     var mypass = encodeURIComponent(contact_number);
      //const res = await trackPromise(axios.post(`${apiUrl}/auth/verify-user`, {contact_number,code}, config))
      const res1 = await trackPromise(axios.get(`https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/dra/verify-user?code=`+code+'&contact_number='+mypass, config))
      
      
      if(!res1){
          console.log('No Data Found')
      }
      else if (res1.data.access_token){

        toast.success("Passed");
          const setlocalStorage =  await localStorage.setItem('jwtToken', res1.data.access_token)
          const setAuth = await setIsAuthenticated(jwt.decode(res1.data.access_token))


     
     //   wait(3000)

         window.location.replace("/")
          // dispatch({
          //     type: FETCH_OTP,
          //     payload: {message : 'Success Login', token : res.data.access_token}
          //  });
          return "200OK";

      }
      else{
 //       toast.success(res1.data.message)
        return "200OK";
      }
      

      } catch (error){

        //  toast.success(error.response.status)
      }

    try{
      //const res = await trackPromise(axios.post(`${apiUrl}/auth/verify-user`, {contact_number,code}, config))
      const res1 = await trackPromise(axios.get(`https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/dra/verify-user?code=`+code+'&contact_number='+mypass, config))
      
      toast.success("Connecting ...");
      if(!res1){
          console.log('No Data Found')
      }
      else if (res1.data.access_token){

          const setlocalStorage =  localStorage.setItem('jwtToken', res1.data.access_token)
          const setAuth =  setIsAuthenticated(jwt.decode(res1.data.access_token))

       //   toast.success(res.data.access_token)

          window.location.replace("/")
          // dispatch({
          //     type: FETCH_OTP,
          //     payload: {message : 'Success Login', token : res.data.access_token}
          //  });
          return "200OK";

      }
      else{
         
  //      toast.success(res1.data.message)
        return "200OK";
      }
      

      } catch (error){

      //    toast.success(error.response.status)
      }


    try{
        //const res = await trackPromise(axios.post(`${apiUrl}/auth/verify-user`, {contact_number,code}, config))
        //const res = await trackPromise(axios.post(url, {contact_number,code}, config))
        
        const res = await trackPromise(
            axios({
                method: 'post',
                url : url,
                data: qs.stringify({
                  code: code,
                  contact_number: contact_number
                }),
                headers: {
                  'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
              }))

        if(!res){
            console.log('No Data Found')
        }
        else if (res.data.access_token){

            const setlocalStorage = await localStorage.setItem('jwtToken', res.data.access_token)
            const setAuth = await setIsAuthenticated(jwt.decode(res.data.access_token))

         //   toast.success(res.data.access_token)

            window.location.replace("/")
            // dispatch({
            //     type: FETCH_OTP,
            //     payload: {message : 'Success Login', token : res.data.access_token}
            //  });
            return "200OK";

        }
        else{
             toast.success("Otp Error")
             dispatch({
                type: FETCH_OTP,
                payload: res.data
             });

        }
        

        } catch (error){

     //       toast.success(error.response.status)
 //           toast.success(error.response.data)
 
            return "Internal server error"
        }

    //    toast.success("Otp Error")
}




export const resendOtp = (number) => async dispatch =>{

  var  shop = sessionStorage.getItem('shop')
  
  var  shopname = "CASHBOX"

  if (shop==='Thye Joo Pawnshop')
    {
    shopname = 'THYEJOO'  
    }
    if (shop==='Thye Eik Pawnshop')
      {
      shopname = 'THYEEIK'  
      }
//      sessionStorage.setItem('shop', 'Marsiling Pawnshop')  
if (shop==='Marsiling Pawnshop')
  {
  shopname = 'MARSILING'  
  }
     
  toast.success(shopname)

    try{
        const res = await trackPromise(axios.post(`${apiUrl}/auth/resend-sms`, {number, shopname}, config))
        
        if(!res){
            console.log('No Data Found')
        }
        
        else{
           toast.success('Code Resend Succesful')
        }
        

        } catch (error){

            return "Internal server error"
        }


}

