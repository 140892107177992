import React, { useState, useEffect } from "react";
import Avatar from "../../../node_modules/@material-ui/core/Avatar";
import Button from "../../../node_modules/@material-ui/core/Button";
import CssBaseline from "../../../node_modules/@material-ui/core/CssBaseline";
import TextField from "../../../node_modules/@material-ui/core/TextField";
import Link from "../../../node_modules/@material-ui/core/Link";
import Grid from ".../../../node_modules/@material-ui/core/Grid";
import Typography from "../../../node_modules/@material-ui/core/Typography";
import { makeStyles } from "../../../node_modules/@material-ui/core/styles";
import Container from "../../../node_modules/@material-ui/core/Container";
import "react-toastify/dist/ReactToastify.css";
import { register } from "../../actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import history from '../../history';
import {  toast } from 'react-toastify';
import {useHistory} from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArrowBack from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import {logout} from '../../actions/authActions';

import Autocomplete from '@material-ui/lab/Autocomplete';



export default function LoginPage() {
  const [form, setForm] = useState({
    username: "",
    alias_username: "",
    sms_handphone: "",
    password: "",
    password_confirmation: "",
    email: ""
  });
  const dispatch = useDispatch()
const authData = useSelector(state => state.auth.register)
const isAuthenticated = useSelector(state => state.auth.isAuthenticated)

const [isAuthenticate, setIsAuthenticate] = useState(false);

useEffect(() => {


  
   if(authData.message == "Credentials Correct"){
    
    var now = new Date();
    var minutes = 5;
    now.setTime(now.getTime() + (minutes * 60 * 1000));
    document.cookie = `otp_number=${authData.number}; expires= ${now.toUTCString()}`


    setIsAuthenticate(true);
  }
  
  }, [authData]);

  const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    avatar: {
      margin: theme.spacing(1),
      height: theme.spacing(9),
      width: theme.spacing(9),
      
    },
    
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    }
  }));

  function validate() {
    if (
      form.username == "" ||
      form.alias_username == "" ||
      form.username == "" ||
      form.sms_handphone == "" ||
      form.password == "" ||
      form.password_confirmation == "" ||
      form.email == ""
    ) {
      //toast.error("Please input all fields");
      return false;
    } else if (form.password != form.password_confirmation) {
      //toast.error("Confirm Password Does Not Match!");
      return false;
    }

    return true
  }



  function validateic() {
    

    return true
  }


  function handleChange(event) {
    const value = event.target.value;
    setForm({ ...form, [event.target.name]: value });

  //  if (event.target.name === "aliasictype")
    
//  if (event.target.name === "alias_username")
     // //toast.error(event.target.name)
    
  }

  function submitForm() {
    const isValidate = validate();

    
    var ictype = document.getElementById('ictype').value
    if (ictype === 'SINGAPORE NRIC (S)') {ictype="1"}
    if (ictype === 'SINGAPORE NRIC (T)') {ictype="2"}
    if (ictype === 'PASSPORT NO') {ictype="3"}
    if (ictype === 'WORK PERMIT NO') {ictype="4"}
    if (ictype === 'MALAYSIA') {ictype="5"}
    if (ictype === 'OTHER') {ictype="0"}
    
    form.ictype= ictype;

    if (isValidate == true) {
      dispatch(register(form))
    }
  }

  function dogoback() {
    dispatch(logout());
  }


  function handleChangeShop(event) {

    alert('Select Only');
    const value = event.target.value;

    setForm({ ...form, [event.target.name]: value });
  }
  
  const classes = useStyles();

  if(isAuthenticate){
    return <Redirect to='/otp' />;

  }
  if(isAuthenticated){
    history.push('/')
  }


  const top100Films = [
    //{ title: 'Dranix Demo Pawnshop', year: 1 },
    { title: 'SINGAPORE NRIC (S)', year: 1 },
    { title: 'SINGAPORE NRIC (T)', year: 2 },
    { title: 'FIN', year: 3 },
    { title: 'MALAYSIA IC', year: 5 },
  ];
   
  

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
     
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          onChange={handleChange}
        />

<div>
<p></p>
</div>

<Autocomplete
    variant="outlined"
    margin="normal"
  
  id="ictype"
  name="ictype"
      
  options={top100Films}
  getOptionLabel={(option) => option.title}
  fullWidth
  required
       
  renderInput={(params) => <TextField {...params}  label="Type of IC" variant="outlined" onChange={handleChangeShop}
  />}
  />  



<TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="alias"
       //   label="PP/NO or NRIC ('S' OR 'T' NOT REQUIRED)"
          label="PP/NO or NRIC ('S' NOT REQUIRED)"
          name="alias_username"
          autoComplete="alias_username"
          onChange={handleChange}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password_confirmation"
          label="Confirm Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={handleChange}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address "
          name="email"
          autoComplete="email"
          onChange={handleChange}
        />
    
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="mobile_number"
          label="Mobile Number (eg. +6591234567)"
          name="sms_handphone"
          type="text"
          onChange={handleChange}
        />

        





        <Button
          type="text"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={submitForm}
        >
          Sign Up
        </Button>

        
        <Grid container>
          <Grid item xs>
          
          </Grid>
          <Grid item>
         
          
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              dogoback();
              
              }}
          >
          <ArrowBack /></IconButton>
          <Button size="small" style={{textTransform: 'none'}} color="primary" onClick={() =>      dogoback()}>Go Back</Button>
          
          
          </Grid>
          <Grid item>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
