import React from 'react';
import {paymentSuccess} from '../../actions/paymentActions';
import { useDispatch } from 'react-redux'


export default function TotalModal(props) {
  
  
  var subtotal_price = 0;
  var interest = 0
  for (let i = 0; i < props.data.length; i++) {
    let data = props.data[i];
    interest = data.interest_payable * 1000
    let interest_1 =  parseInt(interest, 10);
    subtotal_price = subtotal_price + interest_1 ;
  }
  
//  subtotal_price='3.33'
 subtotal_price = (subtotal_price / 1000).toFixed(2)

 var k = props.data.length;
 
 sessionStorage.setItem('admin_price', '0' );
 sessionStorage.setItem('subtotal_price', subtotal_price );
 sessionStorage.setItem('total_price', subtotal_price );
 sessionStorage.setItem('total_count', k );
 
  
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return (<div>
    
    
    Int: {formatter.format(subtotal_price)}

    
  </div>);
}
