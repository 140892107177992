import { useState, useEffect } from "react";
import React from 'react'
import Spinner from '../layout/Spinner';
import { confirmAlert } from 'react-confirm-alert'; // Import
import styles from './OApp.module.css';

import Avatar from "../../../node_modules/@material-ui/core/Avatar";
import Button from "../../../node_modules/@material-ui/core/Button";
import CssBaseline from "../../../node_modules/@material-ui/core/CssBaseline";
import TextField from "../../../node_modules/@material-ui/core/TextField";
import Typography from "../../../node_modules/@material-ui/core/Typography";
import { makeStyles } from "../../../node_modules/@material-ui/core/styles";
import Container from "../../../node_modules/@material-ui/core/Container";
import "react-toastify/dist/ReactToastify.css";
import { register } from "../../actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import history from '../../history';
import {  toast } from 'react-toastify';
import axios from 'axios';
import {logout} from '../../actions/authActions';

import {  useLocation  } from "react-router-dom";
import { AxiosResponse, AxiosError } from 'axios'
import { trackPromise } from 'react-promise-tracker'



// ==========================================================================================================
// URL and authorization header
// ==========================================================================================================
//const apiUrl = 'https://teckhingonlinesoutheast.azurewebsites.net/online-pdms/public/api'
//const apiUrl_0001 = 'https://teckhingonlinesoutheast.azurewebsites.net/online-pdms/public/api'

const apiUrl = 'https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api'
const apiUrl_backup = 'https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api'
//apiurl = "https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api"

function setAuthorizationToken (token){
    if(token){
        return {headers:{
            'Authorization': `Bearer ${token}`
        }}
    }
    else {
        return false    
    }
}
const config = setAuthorizationToken("MiA4MzA0In0.Lbs5QaXwinnwsKwpfy3APkO3poUjBljVOOcIk6-XUIQTEST")
// ==========================================================================================================

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


sessionStorage.removeItem('TrustcareGroup' )  


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}



export default function Resetpassword() {
  const [form, setForm] = useState({
    username: "",
    alias_username: "",
    sms_handphone: "",
    password: "",
    password_confirmation: "",
    email: ""
  });
  const dispatch = useDispatch()
const authData = useSelector(state => state.auth.register)
const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
const [loaded, setLoaded] = useState(true)
    
const {  width } = useWindowDimensions();

const [isAuthenticate, setIsAuthenticate] = useState(false);
 const windowUrl = window.location.search;
 
 var pureText = windowUrl;
   
  let query = useQuery();

  pureText=query.get("name")
  

  function doOK() {

    if (width < 600)
  
    {
    confirmAlert({
      customUI: ({ onClose }) => {
    return (
  
    <div>
    <h1>Message</h1>
        
        <p>The Password is changed</p>
        
        <button class={styles.button} 
              onClick={() => {
                window.location.replace('/');
                onClose();
                dispatch(logout());

              }}
            
              >
                OK</button>
                
        
        </div>
        );
      }
     });  
  
    }
    else
   
    confirmAlert({
      customUI: ({ onClose }) => {
    return (
  
   
    <div class={styles.body}>
    <h1>Message</h1>
        
    <p>The Password is changed.</p>
    
    <button class={styles.button} 
          onClick={() => {
            window.location.replace('/');
            onClose();
            dispatch(logout());

          }}
        
          >
            OK</button>
            
         
  
        </div>
        );
      }
     });  
  
 //       alert('Your Request is submitted. The Password will be reset within 24hrs. Our staff will contact you.')  // onlinePDMS API test
    }
  

function callapi()
  {
    
    form.username = pureText;

    setLoaded(false)

    if (form.username == "")
    {
      alert('No Username');
    }
  else
  {
   
//    //toast.error("Please wait. Updating");
    {
     
    var username = pureText;
    var password = form.password;
    var lastFive = username.substr(username.length - 5); // => "Tabs1"

    if (lastFive === '_0001')
    {
     // //toast.error('Note: Process - Test Site.')  // onlinePDMS API test
      var str = username;
      str = str.substring(0, str.length - 5);
     // oast.error(str);
     username = str;
     
     console.log(username);
     console.log(password);
      axios.post(`${apiUrl}/dra/changepassword`, {username, password}, config )  
      .then(response => {
        setLoaded(true)

        console.log('-------------------------------------------------------------------DRANIX API CALL ONCE');
    //    console.log(response.data);
    //    //toast.error(response.data);
        //    this.setState({Tododata: response.data} )
        sessionStorage.setItem('mymessage', 'Your Request is successful. ');
        localStorage.setItem('mylogin', 'Your Request is successful. ');
       // //toast.error('Your Request is successful. ');
       doOK();
      //  alert('Note: Request is successful. ')  // onlinePDMS API test
    //    alert(response.data)  // onlinePDMS API test
     //   dispatch(logout());
  
      })
      .catch(error => {

        setLoaded(true)

        console.log('"API CALL fail');
        //console.log(error);
        
        sessionStorage.setItem('mymessage', 'Your Request is unsuccessful. ');
        localStorage.setItem('mylogin', 'Your Request is unsuccessful. ');
        ////toast.error('Your Request is unsuccessful. ');

        if (error.response.status === 410) {
          // Handle 400
          alert('Note: The Reset link has expired.')  // onlinePDMS API test
        } else {
          // Handle else
          alert('Note: Your Request is unsuccessful.')  // onlinePDMS API test
        }
        dispatch(logout());
      });
  
    
    
      }
    else
    {
    //  //toast.error('Note: Processing.')  // onlinePDMS API test
    trackPromise(axios.post(`${apiUrl}/dra/changepassword`, {username, password}, config ))  
      .then(response => {
        setLoaded(true)

        console.log('-------------------------------------------------------------------DRANIX API CALL ONCE');
     //   console.log(response.data);
        //    this.setState({Tododata: response.data} )
        sessionStorage.setItem('mymessage', 'Your Request is successful. ');
        localStorage.setItem('mylogin', 'Your Request is successful. ');
       // //toast.error('Your Request is successful. ');
        doOK();
     //   alert('Note: Request is successful. ')  // onlinePDMS API test
    //    dispatch(logout());
  
      })
      .catch(error => {
        setLoaded(true)

        console.log('"API CALL fail');
     //   console.log(error);
        
        sessionStorage.setItem('mymessage', 'Your Request is unsuccessful. ');
        localStorage.setItem('mylogin', 'Your Request is unsuccessful. ');
        ////toast.error('Your Request is unsuccessful. ');
        alert('Note: Your Request is unsuccessful.')  // onlinePDMS API test
        dispatch(logout());
      });
  
    }
    }
    }
    

  //  console.log(username);
  //  axios.post(`${apiUrl}/dra/changepassword`, {username, password}, config )
  //  const res = await trackPromise(axios.post(`${apiUrl}/auth/login`, {username, password}, config))

    
  }


useEffect(() => {


  
   if(authData.message == "Credentials Correct"){
    
    var now = new Date();
    var minutes = 5;
    now.setTime(now.getTime() + (minutes * 60 * 1000));
    document.cookie = `otp_number=${authData.number}; expires= ${now.toUTCString()}`


    setIsAuthenticate(true);
  }
  
  }, [authData]);

  const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    }
  }));

  function validate() {
    if (
      form.password == "" ||
      form.password_confirmation == "" 
      
    ) {
      //toast.error("Please input all fields");
      return false;
    } else if (form.password != form.password_confirmation) {
      //toast.error("Confirm Password Does Not Match!");
      return false;
    }

    return true
  }


  function handleChange(event) {
    const value = event.target.value;
    setForm({ ...form, [event.target.name]: value });
  }

 


  function savepassword() {
    const isValidate = validate();

    if (isValidate == true) {
      callapi();
    }
  }


  const classes = useStyles();

  if(isAuthenticate){
    return <Redirect to='/otp' />;

  }
  if(isAuthenticated){
    history.push('/')
  }

  
// http://localhost:3000/resetpassword?me42
console.log("----------------------------------------------------------------------start");


  //const parsed = queryString.parse(windowUrl);
//  console.log(windowUrl);
 
//console.log(pureText);

//pureText = "me42";

console.log("----------------------------------------------------------------------end");

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}></Avatar>


        {!loaded ? <Spinner /> : 
        (<React.Fragment>

        <Typography component="h1" variant="h5">
          Reset password
        </Typography>
        
        <Typography component="h1" variant="h6">
         
     {/*  User: {pureText}     <User name={query.get("name")} /> */}
        </Typography>
      

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="New Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password_confirmation"
          label="Confirm Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={handleChange}
        />

        <Button
          type="text"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={savepassword}
        >
          Save
        </Button>

        </React.Fragment>
        )
        }
      </div>
    </Container>
  );
  
}
