import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paynowbutton from '../payments/paynowbutton'
import Paynowgatewaybutton from '../payments/paynowgatewaybutton'
import Container from "@material-ui/core/Container";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { useDispatch } from 'react-redux'
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useEffect } from 'react';
import {paymentSuccess} from '../../actions/paymentActions';
import {paycallback} from '../../actions/authActions';
import {logout} from '../../actions/authActions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import styles from './OApp.module.css';
import { useHistory } from "react-router-dom";
import {  toast } from 'react-toastify';
import QRCode from 'qrcode.react';
import { trackPromise } from 'react-promise-tracker'
import jwt from 'jsonwebtoken'
import axios from 'axios';
import {PAYPAL_PAYMENT_SUCCESS} from '../../reducers/types/paymentTypes';

const apiUrl = 'https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api'
const auth = jwt.decode(localStorage.jwtToken)
const apiUrl_backup = 'https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api'


var encryptedStr = "";


function wait(ms){
  var start = new Date().getTime();
  var end = start;
  while(end < start + ms) {
    end = new Date().getTime();
 }
}


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow:'hidden',
    minWidth: '420px',
    
    width: '15%'
  },
  table: {
    minWidth: 800,
  },
  box: {
    height: 100,
    display: "flex",
    border: "1px solid black",
    padding: 8
  },
  spreadBox: {
    justifyContent: 'space-between',
    alignItems: "center"
  }
}));





  



function SimpleDialog(props) {

  const dispatch = useDispatch()
  const [dopenmy, setDopenmy] = React.useState(false);
  const [dopen_refmy, setDopen_refmy]= React.useState(true);
  const history = useHistory();
   
  var QRstring


  const paymentHandler = (details, data) => {
 
    /** Here you can call your backend API endpoint and update the database    */
    localStorage.setItem('paydone', JSON.stringify(props.data));
    encryptedStr = localStorage.getItem('data.orderIDInLocalStorage')
    
    QRstring = sessionStorage.getItem('mydataqr')

    sessionStorage.setItem('finalmsg',"YES")

    var gateway_fee=sessionStorage.getItem('admin_price')  
    var subtotal_price=sessionStorage.getItem('subtotal_price')  
    total_price=sessionStorage.getItem('total_price')  
    var username=sessionStorage.getItem('username')  

    gateway_fee=sessionStorage.getItem('admin_price')  
    subtotal_price=sessionStorage.getItem('subtotal_price')  
    total_price=sessionStorage.getItem('total_price')  
    username=sessionStorage.getItem('username')  

    var res1,res2
    var mycount
    var data = props.data
    var order_id= "Paynow :"+props.data.orderID
    mycount = 0
    var i

   //axios.post(`${apiUrl}/dra/forgotpassword`, config)
       
        dispatch(paymentSuccess(props.data, "Paynow :"+encryptedStr ))
       
    
   confirmAlert({
    customUI: ({ onClose }) => {
    return (

   //   <h1>Ref:{encryptedStr}</h1>
   //<p>{shopname} / {myuen} </p>      
    <div>

      <p></p>
      <div><p></p></div>  
      <p></p>
      <div><p></p></div>  
      <p></p>
      <div><p></p></div>  
      <p></p>
      <Box display="flex" flexDirection="row-reverse" p={1} m={1} bgcolor="background.paper" alignItems="center" justifyContent="center" >
      <QRCode value={QRstring} size={160}/>
      </Box>
      <div></div>
      <p></p>
      <div><p></p></div>  
      <p></p>
      <p></p>
      <p>Save this QR code to your photos.</p>
      <p>Use your Bank App from DBS/OCBC/UOB or Payment App that allow payment to PAYNOW QR code.    </p>
      <p>Renewal will be cancel if PAYNOW payment not received within 10 minutes.</p>
      <p></p>
      <p></p>
      <button class={styles.button} 
      onClick={() => {
  //      onClose();
 //       toast.success("Logout");
 toast.success('Message : Logout');
 localStorage.removeItem("jwtToken")  
 sessionStorage.setItem('Logout', 'done');

 toast.success('logout');
 window.location.replace('/')

        dispatch(logout());
 //       onClose();
      }}
      >
        Click here to Logout</button>
    </div>
    );
    },
    closeOnEscape: true,  //**only press on ESC on keyboard to close**
       closeOnClickOutside: false 
    });   

   let propsdata = localStorage.getItem('propsdataInLocalStorage')
   let selected = localStorage.getItem('selectedInLocalStorage')
   var temp = localStorage.getItem('example');
   var viewName = JSON.parse(temp); 
   encryptedStr = localStorage.getItem('data.orderIDInLocalStorage')
    
  
      
         var temp = sessionStorage.getItem('mydata');
         var viewName = JSON.parse(temp); 
         var orderid = sessionStorage.getItem('data.orderIDInLocalStorage')  
         viewName.orderID = sessionStorage.getItem('data.orderIDInLocalStorage')  
     
         wait(100);  //7 seconds in milliseconds  
         
   
         
    
   
   }
   

 
  const { onClose, selectedValue, open } = props;
  const handleClose = () => {

    localStorage.setItem('paydone', JSON.stringify(props.data));
    
     const removeLocalStorage = localStorage.removeItem("jwtToken");
 //    dispatch(logout());
     toast.success("Message: Payment Cancel");
     //wait(3000)

     confirmAlert({
      customUI: ({ onClose }) => {
      return (
      <div>
        <h1>Message</h1>
        <p>Renewal will be cancel.</p>
        <button class={styles.button} 
        onClick={() => {
         // onClose();
  //        toast.success("Message : Logout");
   
          dispatch(logout());
   //       onClose();
        }}
        >
          Click here to Logout</button>
      </div>
      );
      },
    closeOnEscape: true,  //**only press on ESC on keyboard to close**
       closeOnClickOutside: false 
      });   
  
     
     onClose(selectedValue);
 
   };



  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1.5),
    },
  }))(MuiDialogActions);


  var total_price = 0
  for (let i = 0; i < props.data.length ; i++) {

    let data = props.data[i];

    total_price = total_price + parseInt(data.interest_payable)

  }


  var shopname = sessionStorage.getItem('shop')  
  var isactive,myuen
  
  
  if (shopname === 'Cashbox Shop A'  )
  {
  isactive = true
  myuen =  '200808005M'
  
  }

if (shopname === 'Marsiling Pawnshop'  )
{
isactive = true
myuen =  '200303738E'

}
  if (shopname === 'Cashbox West Coast'  )
  {
  isactive = true
  myuen =  '200808005M'
  
  }
  if (shopname === 'Thye Joo Pawnshop'  )
  {
    isactive = true
    myuen =  '199607075D'
  }
  if (shopname === 'Thye Eik Pawnshop'  )
  {
    isactive = true
    myuen =  '199206304R'
  }
  
   

  return (
    <Dialog  aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="Payment"></DialogTitle>
   
      <Container component="main" maxWidth="xs">

      <Paynowbutton  data={props.data} total={total_price} orderID={total_price} />
  
     
 
        
      <DialogActions>

      <Box component="span"  m={1}
>

      <Button autoFocus onClick={paymentHandler} color="primary" fullWidth variant="contained" size="small" style={{ borderRadius: 20, padding: '60px 20px 60px 20px', textAlign: 'center', height: 80 , width: 190 , margin: '0px 8px 15px 0px'}}>
            Accept Here (Click). 
          </Button>
      
          <Button autoFocus onClick={handleClose} color="secondary" fullWidth variant="contained" size="small" style={{ borderRadius: 20 , padding: '60px 20px 60px 20px' , textAlign: 'center',height: 80 ,width: 150 , margin: '0px 0px 15px 8px'}}>
            Cancel Here
          </Button>
    
          </Box>

        </DialogActions>

        </Container>
    </Dialog>

    
  );
}


SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};




function SimpleDialog_M(props) {
  
  
  
  


  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };


  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1.5),
    },
  }))(MuiDialogActions);

  const style = {
    layout: "horizontal", // horizontal | vertical
    size: "responsive", // medium | large | responsive
    shape: "rect", // pill | rect
    color: "gold" // gold | blue | silver | white | black
  };

  var total_price = 0
  for (let i = 0; i < props.data.length ; i++) {

    let data = props.data[i];

    total_price = total_price + parseInt(data.interest_payable)

  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="Payment"></DialogTitle>
   
      <Container component="main" maxWidth="xs">
   
     
      <Paynowgatewaybutton  data={props.data} total={total_price} orderID={total_price} />
  
     
 
   
      </Container>

      <DialogActions>
    
          <Button autoFocus onClick={handleClose} color="secondary" variant="contained" size="small">
            Cancel
          </Button>
        </DialogActions>

    </Dialog>
  );
}


SimpleDialog_M.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};


export default function SelectListModal(props) {
  

  const classes = useStyles();
  const [dopen, setDopen] = React.useState(false);
  const [dopen_m, setDopen_m] = React.useState(false);
  const [dopen_ref, setDopen_ref]= React.useState(true);
  const [Loading, setLoading] = React.useState(false);

  const dhandleClickOpen = () => {
    if (total_price>0) {
      setDopen(true);
    } else {
    
    }
  };

  const dhandleClose = (value) => {
    setDopen(false);
  };

  const dhandleOPen = (value) => {
    
  };

  const dhandleClickOpen_m = () => {
    if (total_price>0) {
      setDopen_m(true);
    } else {
    
    }
  };

  const dhandleClose_m = (value) => {
    setDopen_m(false);
  };

  var total_price = 0

  for (let i = 0; i < props.data.length ; i++) {
    let data = props.data[i];
    total_price = total_price + parseInt(data.interest_payable)
  }


  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {

    encryptedStr = localStorage.getItem('data.orderIDInLocalStorage')
    
    if (dopen_ref)
    {

 

    setDopen_ref(false);
    props.data.orderID = encryptedStr
    
    

    }

    
  });

  var shopname = sessionStorage.getItem('shop')  
  var isactive

  if (shopname === 'Cashbox West Coast'  )
  {
  isactive = true
  }
  if (shopname === 'Cashbox Shop A'  )
  {
  isactive = true
  }
  if (shopname === 'Marsiling Pawnshop'  )
  {
  isactive = true
  }
  
  if (shopname === 'Thye Joo Pawnshop'  )
  {
    isactive = true
  }
  if (shopname === 'Thye Eik Pawnshop'  )
  {
    isactive = true
  }

  
  return (
    <div>

    <Grid
      container 
    >
      <Grid item>

{/*
 
      <div id="paypal-button-container"></div>
      <PaymentPaypal data={props.data} total={total_price}  />       
 
*/}

      
{isactive ?  
      
      <Button type="submit" fullWidth variant="contained" color="secondary" className={classes.submit} onClick={dhandleClickOpen}>
      Paynow - Scan and Click Here
      </Button>
      
 : 
 <Button type="submit" fullWidth variant="contained" color="secondary" className={classes.submit} >
 QRcode Not Avail - Unable to Process Payment 
 </Button>

}

<SimpleDialog  open={dopen} onClose={dhandleClose}  data={props.data} />
     

    <div>
    <p> </p>
    </div>

 {/*
  
      <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} onClick={dhandleClickOpen_m}>
      Paynow Maesh Gateway
      </Button>
      <SimpleDialog_M  open={dopen_m} onClose={dhandleClose_m} data={props.data} />
  
   */}
  
      <div>
      <p> 
      </p>
      </div>

 
 
   
      <div>
<p> 
</p>
</div>

      </Grid>
    </Grid>
    </div>
  );
}
