import {PAYPAL_PAYMENT_SUCCESS} from '../reducers/types/paymentTypes';
import axios from 'axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jsonwebtoken'
import { trackPromise } from 'react-promise-tracker'

const apiUrl = 'https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api'
const auth = jwt.decode(localStorage.jwtToken)
const apiUrl_backup = 'https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api'

axios.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes("timeout") || message.includes("Network Error"))) {
  //    toast.error("err 2")
   //   return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        toast.error("Please wait")
        console.log("retry the request", config.url);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axios(config));
  });
  

export const paymentSuccess = (data ,order_id) => async dispatch =>{

    var mycount 
    var test 
    var res

    test = 'y';

    var  shop = sessionStorage.getItem('shop')
  
    var  shopname = "CASHBOX"
  
    if (shop==='Thye Joo Pawnshop')
      {
      shopname = 'THYEJOO'  
      }
      if (shop==='Thye Eik Pawnshop')
        {
        shopname = 'THYEEIK'  
        }
  //      sessionStorage.setItem('shop', 'Marsiling Pawnshop')  
  if (shop==='Marsiling Pawnshop')
    {
    shopname = 'MARSILING'  
    }
       
    toast.success(shopname)
    try{

        var gateway_fee=sessionStorage.getItem('admin_price')  
        var subtotal_price=sessionStorage.getItem('subtotal_price')  
        var total_price=sessionStorage.getItem('total_price')  
        var username=sessionStorage.getItem('username')  
        var total_count=sessionStorage.getItem('total_count')  
        
        mycount = 0

     
        

            var i 
            
            try {

                res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000,  username,  test}))

                res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000 ,data , auth , order_id, gateway_fee, subtotal_price, total_price, username,shopname}))
                if (String(res.data.count)===String(total_count)) 
                {    if (String(res.data.count)===String(total_count)) 
                    { toast.success("match OK")
                  
                    dispatch({
                     type: PAYPAL_PAYMENT_SUCCESS,
                     payload: res.data
                  });
                  return;}}
                
                  res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000 ,data , auth , order_id, gateway_fee, subtotal_price, total_price, username,shopname}))
                  if (String(res.data.count)===String(total_count)) 
                  {    if (String(res.data.count)===String(total_count)) 
                      { toast.success("match OK")
                    
                      dispatch({
                       type: PAYPAL_PAYMENT_SUCCESS,
                       payload: res.data
                    });
                    return;}}

                for (i = 0; i < 5; i++) {
                    //  toast.success('Server...')
                    res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000 ,data , auth , order_id, gateway_fee, subtotal_price, total_price, username,shopname}))
                    if (String(res.data.count)===String(total_count)) 
                        {    if (String(res.data.count)===String(total_count)) 
                            { toast.success("match OK")
                            toast.error(String(res.data.count)+"="+String(total_count));            
                            dispatch({
                             type: PAYPAL_PAYMENT_SUCCESS,
                             payload: res.data
                          });
                          return;}}
                        
                      if(res.data.message === "testing : ") 
                        {
                            toast.error('Please wait')
                            break;
                        }    
                      
                      
                  }
                  } catch (error){}   

                  
            for (i = 0; i < 5; i++) {
        
            res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000 ,data , auth , order_id, gateway_fee, subtotal_price, total_price, username,shopname}))
            if (String(res.data.count)===String(total_count)) 
                {    if (String(res.data.count)===String(total_count)) 
                    { toast.success("match OK")
                  
                    dispatch({
                     type: PAYPAL_PAYMENT_SUCCESS,
                     payload: res.data
                  });
                  return;}}
            
            }

        

        
 
              

//    toast.success(res.data.count+"<=>"+total_count)
    if (String(res.data.count)===String(total_count)) 
       { toast.success("match 1 ")
       dispatch({
        type: PAYPAL_PAYMENT_SUCCESS,
        payload: res.data
     });
     return;
 
    }
        else
        {
                 
            res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { data , auth , order_id, gateway_fee, subtotal_price, total_price, username,shopname}))
            console.log(res.data, 'suc')
            mycount = mycount + 1 
   
        
        }

        if (String(res.data.count)===String(total_count)) 
        { toast.success("match 1-1")
        dispatch({
            type: PAYPAL_PAYMENT_SUCCESS,
            payload: res.data
         });
         return;
        }

            if(res.data.message === "Payment Successful"){

                
                dispatch({
                    type: PAYPAL_PAYMENT_SUCCESS,
                    payload: res.data
                 });


            }
            else {
                //toast.error(res.data.message)
                dispatch({
                    type: PAYPAL_PAYMENT_SUCCESS,
                    payload: res.data
                 });
            }
       
        } catch (error){

            

           // //toast.error("Internal Server Error");
       //    toast.success("OK")
    

           try{

             gateway_fee=sessionStorage.getItem('admin_price')  
             subtotal_price=sessionStorage.getItem('subtotal_price')  
            total_price=sessionStorage.getItem('total_price')  
            username=sessionStorage.getItem('username')  
    
            mycount = 0
        
            
            toast.success('Payment Updating .. Now')
         
            try {
            for (i = 0; i < 5; i++) {
                //  toast.success('Server...')
                  res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000,  username,  test}))
                  
                  if(res.data.message === "testing : ") 
                    {
                        break;
                    }    
                  
                  
              }
              } catch (error){}   

                res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000, data , auth , order_id, gateway_fee, subtotal_price, total_price, username,shopname}))
                console.log(res.data, 'suc')
                mycount = mycount + 1 
       
   


            if (String(res.data.count)===String(total_count)) 
            { toast.success("match 2-2 ")
            dispatch({
             type: PAYPAL_PAYMENT_SUCCESS,
             payload: res.data
            })
            return;
            
            };
    
                if(res.data.message === "Payment Successful"){
    
                    toast.success('Payment is processing !!')
                    dispatch({
                        type: PAYPAL_PAYMENT_SUCCESS,
                        payload: res.data
                     });
                }
                else {
                    //toast.error(res.data.message)
                    dispatch({
                        type: PAYPAL_PAYMENT_SUCCESS,
                        payload: res.data
                     });
                }
           
            } catch (error){
    
                
                res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000 ,data , auth , order_id, gateway_fee, subtotal_price, total_price, username,shopname}))
            
                if (String(res.data.count
                    )===String(total_count)) 
                { 
                toast.success("match 3-3 ")
                dispatch({
                 type: PAYPAL_PAYMENT_SUCCESS,
                 payload: res.data
                });
                return;
                }
    
            

             
            }
            res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000 ,data , auth , order_id, gateway_fee, subtotal_price, total_price, username,shopname}))
            
            if (String(res.data.count
                )===String(total_count)) 
            { 
            toast.success("match 3-3 ")
            dispatch({
             type: PAYPAL_PAYMENT_SUCCESS,
             payload: res.data
            });
            return;
            }
    
    
        }

      
        res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000 ,data , auth , order_id, gateway_fee, subtotal_price, total_price, username,shopname}))
            
        if (String(res.data.count
            )===String(total_count)) 
        { 
        toast.success("match 3-3 ")
        dispatch({
         type: PAYPAL_PAYMENT_SUCCESS,
         payload: res.data
        });
        return;
        }
    
 
        
        if (String(res.data.count
            )===String(total_count)) 
        { 
        toast.success("match 3-3 ")
        dispatch({
         type: PAYPAL_PAYMENT_SUCCESS,
         payload: res.data
        });
        return;
        }

        toast.error(String(res.data.count)+"="+String(total_count));
        toast.error("Server Error");
        localStorage.removeItem("jwtToken")  
        sessionStorage.setItem('Logout', 'done');
        localStorage.removeItem("mylogin")  
        

        var shop = sessionStorage.getItem('shop')  
        var shopname=""
       
          shopname = 'Session Expired'
          window.location.replace('/')
          return ;
}