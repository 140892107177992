import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import "../../App.css";
import {logout} from '../../actions/authActions';
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import { getAllTicket } from "../../actions/ticketActions";
import SelectListModal from "../modals/ticketModal";
import TotalModal from "../modals/TotalModal";
import Chip from "@material-ui/core/Chip";
import "./TicketList.css";
import moment from "moment";
import jwt from 'jsonwebtoken'
import 'react-toastify/dist/ReactToastify.css';
import {  toast } from 'react-toastify';
import { isExpired, decodeToken } from "react-jwt";
import Button from '@material-ui/core/Button';
import axios from 'axios';
import setAuthorizationToken from "../../actions/authActions";
import { trackPromise } from 'react-promise-tracker'
import { confirmAlert } from 'react-confirm-alert'; // Import

const drawerWidth = 220;
const token = localStorage.jwtToken;
const auth = jwt.decode(localStorage.jwtToken)
var authexp
var shop

if (auth)
{
  authexp = auth.exp * 1000 * 2
}
else
{
  authexp   = "NULL"
}




function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}




function App_count() {
  const [counter, setCounter] = React.useState(60 * 10);
  const dispatch = useDispatch();

  var dateNow = new Date();
  
 
  React.useEffect(
   () => {
    counter > 0 && 
    setTimeout (() => setCounter(counter - 1), 1000);
      }
  , [counter]

  
  );

  const token = localStorage.jwtToken;
  var decodedToken=jwt.decode(token, {complete: true});
  var dateNow = new Date();
  
  const myDecodedToken = decodeToken(token);
  const isMyTokenExpired = isExpired(token);
  if (isMyTokenExpired) {
    dispatch(logout());
 
   } else {
 
   }

  var countmin = 0
  countmin = parseInt((counter / 60))
 
  

  var countsec = 0
  countsec = counter - (countmin * 60)

  const {  width } = useWindowDimensions();

  if (countmin < 1) {
   dispatch(logout());

  } else {

  }

  if (dateNow.getTime() > authexp) {
  dispatch(logout());

  } else {

  }

  shop = sessionStorage.getItem('shop')  
  if (shop=='')
  {
    dispatch(logout());
  }

  if (shop==null)
  {
    dispatch(logout());
  }

  var rowcount=0
  var mytable = document.getElementById('mytable')
 

  if  (countmin<0)
  { 
    for(var i = mytable.rows.length - 1; i >= 0; i--)
    {
        mytable.deleteRow(i);
    }
  }

  return (
    <div className="App_count">
  <div>
   	
  </div>
  

     
  {/*
  <div>{JSON.stringify(authexp )}</div>
	<div>{JSON.stringify(dateNow.getTime())}</div>
  <div>{countmin}m:{countsec}s</div>
  <div>W{width}</div>
  */}
  
  
  
  <div>{shop}</div>
  
  

   </div>
    
  );
}
//----------------------------------------------------------------------------------























//---------------------------------------------------------------------------------------------------------------
// function TicketList()
//---------------------------------------------------------------------------------------------------------------

export default function TicketList() {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
 //     overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  }));


// USE EFFECT AREA

const [rows, setRow] = useState([]);
const dispatch = useDispatch();
const TicketData = useSelector((state) => state.ticket.ticket);
const paymentData = useSelector((state) => state.payments);
const classes = useStyles();
const [order, setOrder] = React.useState("asc");
const [orderBy, setOrderBy] = React.useState("final_expiry_date");
const [selected, setSelected] = React.useState([]);
const [page, setPage] = React.useState(0);
const [dense, setDense] = React.useState(true);
const [rowsPerPage, setRowsPerPage] = React.useState(8);
const [sw, setSw] = React.useState(false);



var headCells ;

        

  useEffect(() => {



  

   

    var paydone
    var jwt 

    paydone = localStorage.getItem('paydone')
    shop = sessionStorage.getItem('shop')
    jwt = localStorage.getItem("jwtToken");

    var encryptedStr

    const min = 1111;
    const max = 9999;
    var rand = min + Math.random() * (max - min);
    
    
    var date2 = moment().utcOffset('+08:00').format('YYMMDD')
  
  
    var date3 = moment().utcOffset('+08:00').format('hhmmssSSS')
  
    date2=""
    date3=moment().utcOffset('+08:00').format('hhmmssSS')
  
    const rand2 =   date2;
    const rand3 =   date3;
    function idOf(i) {  return (i >= 26 ? idOf((i / 26 >> 0) - 1) : '') +  'abcdefghijklmnopqrstuvwxyz'[i % 26 >> 0];}
    const getColumnName = i => (i >= 26 ? getColumnName(Math.floor(i / 26) -1 ) : '') + 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i % 26];
    
    const convert = {
      bin2dec : s => parseInt(s, 2).toString(10),
      bin2hex : s => parseInt(s, 2).toString(16),
      dec2bin : s => parseInt(s, 10).toString(2),
      dec2hex : s => parseInt(s, 10).toString(16),
      hex2bin : s => parseInt(s, 16).toString(2),
      hex2dec : s => parseInt(s, 16).toString(10)
    };
    
    convert.bin2dec('111'); // '7'
    convert.dec2hex('42');  // '2a'
    convert.hex2bin('f8');  // '11111000'
    convert.dec2bin('22');  // '10110'
    
    function isEven(value) {
      if (value%2 == 0)
        return true;
      else
        return false;
    }

    encryptedStr = parseInt(rand) + getColumnName(rand2) + rand3  ;
    var timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
    //console.log(timeStampInMs, Date.now());
    var unix = Math.round(+new Date()/1000);
    var str = unix;
    str = convert.dec2hex(unix) + convert.dec2hex(parseInt(rand) )
    
      {
        str = (convert.dec2hex(unix)).toUpperCase() + (convert.dec2hex(parseInt(rand) )).toUpperCase();         
      }
    
    encryptedStr = str.substring(0,10)  
    //if(str.length > 10) str = str.substring(0,10)
    localStorage.setItem('data.orderIDInLocalStorage', encryptedStr);
    


    if (jwt==null)
    {
      TicketData.data = null  
      dispatch(logout());
    }

    
    if (jwt=="")
    {
      TicketData.data = null  
      dispatch(logout());
    }

    
    if (jwt!="")
    {

      
    var dateNow = new Date();

    if (dateNow.getTime() > authexp) {
      TicketData.data = null  
      dispatch(logout());
  
    } else {

      if (shop)
        {
       if (!paydone)
       {


   
      
        paydone = localStorage.getItem('paydone')
        if (paydone==null)
        {
          localStorage.removeItem('paydone')
          localStorage.setItem('mylogin', 'done');
              
          setSelected([]);

          if (paydone)
          {
            TicketData.data = null  
            dispatch(logout());
          }
          else
          {  


    
          TicketData.data = null  ;
    //axios.post(`${apiUrl}/dra/forgotpassword`, config)
    //axios.post(`${apiUrl}/dra/forgotpassword`, {username, password}, config )
    trackPromise(axios.get(`https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/dra/getshopname?username=`+username, {username}))
    .then(response => {
      console.log('-------------------------------------------------------------------DRANIX API CALL ONCE');
      console.log(response.data);
  var shop = response.data.data
//    sessionstorage.setItem('shop',shop)
 //   localstorage.setItem('shop',shop)
 //   sessionStorage.setItem('shop', shop)  
//    localStorage.setItem('shop', shop)  
  
 // toast.success("Read " + shop);
 dispatch(getAllTicket());
     
    //  alert('Your Request is submitted. The Password will be reset within 24hrs. Our staff will contact you.')  // onlinePDMS API test
   
    })
    .catch(error => {
      
    });
    
        
        }
          
                
    
     
         
        }
        
          
        else
        {
          TicketData.data = null  
          dispatch(logout());
          
        }

       }
       
        }
        else
        {
          TicketData.data = null  
          dispatch(logout());
        }  
    }

    }
  }, [paymentData]);

      


  useEffect(() => {
    var rowData = [];

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces)
    
    if (TicketData.data) {
      for (let i = 0; i < TicketData.data.length; i++) {
      
      
        let data = TicketData.data[i];
        let amount_with_interest = data.amount;
        let my_amount = data.amount;

        // date if ticket
        let ticket_date = new Date(TicketData.data[i].loan_table.Ticket_Date);
        let ticket_date_year = ticket_date.getFullYear();
        let ticket_date_month = ticket_date.getMonth() + 1;
        let ticket_date_day = ticket_date.getDate();
        // current date

        let current_date = new Date();
        let current_date_year = current_date.getFullYear();
        let current_date_month = current_date.getMonth() + 1;
        let current_date_day = current_date.getDate();

        let dt1 = new Date(
          ticket_date_year,
          ticket_date_month,
          ticket_date_day
        );
        let dt2 = new Date(
          current_date_year,
          current_date_month,
          current_date_day
        );
       
       
        let difference_months = current_date_month-ticket_date_month+1;
        if  (difference_months < 0)  {
          difference_months = difference_months + 12
        }

        if (current_date_day  < ticket_date_day)
        {
          difference_months = difference_months - 1
        }
       
        if  (difference_months > 1)  {
          if (current_date_day  == ticket_date_day)
          {
            difference_months = difference_months - 1
          }
        }

        
        let grant_date = moment(TicketData.data[i].loan_table.Ticket_Date);
        
        let final_grant_date = grant_date.format("DD-MMM-YYYY")  ;
        let mygrantday=grant_date.format("DD")  ;
        grant_date.add(6, "months");
        let myexpday=grant_date.format("DD")  ;
        
        if (mygrantday==myexpday) {grant_date.add(-1, "days");}
             
        let final_expiry_date_display = grant_date.format("DD-MMM-YYYY")  ;
        let final_expiry_date = grant_date.format("YYYY-MM-DD")  ;

        let interest_payable = 0 
        let interest_payable_1 = 0 
        
          
        if ( difference_months == 1 )
          {
                interest_payable = (1 / 100 * my_amount)
          }

          if ( difference_months == 2 )
          {
            interest_payable = (1 / 100 * my_amount) + (1.5 / 100 * my_amount)
          }

          if ( difference_months == 3 )
          {
            interest_payable = (1 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) 
          }

          if ( difference_months == 4 )
          {
             interest_payable = (1 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) 
          }

          if ( difference_months == 5 )
          {
             interest_payable = (1 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount)  
          }

          if ( difference_months == 6 )
          {
             interest_payable = (1 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount)   
          }

          if ( difference_months == 7 )
          {
             interest_payable = (1 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount)     
          }

          if ( difference_months == 8 )
          {
             interest_payable = (1 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount)  + (1.5 / 100 * my_amount)     
             data.ticket_no = "morethan7mth"
          }


          interest_payable_1 = roundAccurately(interest_payable , 2)

          interest_payable = roundAccurately(interest_payable_1 , 3)

        let  item_description = data.item_description_1 
    
    let shop = sessionStorage.getItem('shop')  

   if (shop==null)
   {
    dispatch(logout());
   }

   

    //    item_description = shop
    var str = data.ticket_no;
    var res = str.substring(0, 4);

    if ( difference_months === 8 )
    {
       interest_payable = (1 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount)  + (1.5 / 100 * my_amount)     
       res = "XXXX"
    }
    if ( difference_months > 8 )
    {
       interest_payable = (1 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount) + (1.5 / 100 * my_amount)  + (1.5 / 100 * my_amount)     
       res = "XXXX"
    }

    var mybranch = '____'
    // item_description = res
    // allow only one branch      
    if (shop=='Sembawang Teck Hing')
        {mybranch = '_STH'}
    if (shop=='Sheng Cheong Pawnshop')
        {mybranch = '__SC'}    
    if (shop=='Dranix Computer')
        {mybranch = '__DR'}    
    if (shop=='Dranix Computer Shop A')
        {mybranch = '_DRA'}    
    if (shop=='Dranix Computer Shop B')
        {mybranch = '_DRB'}    
    
    
        if (shop=='Thye Eik Pawnshop')
        {mybranch = '_TEP'}    
    
        if (shop=='Thye Joo Pawnshop')
        {mybranch = '_TJP'}    
    
    
        if (shop=='Cashbox West Coast Shop B')
        {mybranch = '_TCB'}    
    
        if (shop=='Cashbox Shop A')
        {mybranch = '_CBA'}    
    

        if (shop=='Marsiling Pawnshop')
        {mybranch = '_MPS'}    

        if (shop=='Cashbox West Coast')
        {mybranch = '_CWC'}    
    
    if (data.ticket_no !== "morethan7mth")    
      if ((res===mybranch))
      {

        rowData.push(
          createData(
            data.id,
            data.ticket_no,
            data.alias_ticket_no,
            data.amount,
            item_description,
            data.weight,
            data.nation,
            data.is_pending,
            data.loan_table.Interest_Rate,
            data.loan_table.Ticket_Date,
            interest_payable,
            amount_with_interest,
            final_expiry_date,
            final_expiry_date_display,
            final_grant_date,
            difference_months
            
          )
        );
      }
      setRow(rowData);
    }

    }
  }, [TicketData.data]);




  function createData(
    id,
    ticket_no,
    alias_ticket_no,
    amount,
    item_description_1,
    weight,
    nation,
    is_pending,
    interest_rate,
    ticket_date,
    interest_payable,
    amount_with_interest,
    final_expiry_date,
    final_expiry_date_display,
    final_grant_date,
    difference_months 
    
  ) {
    return {
      id,
      ticket_no,
      alias_ticket_no,
      amount,
      item_description_1,
      weight,
      nation,
      is_pending,
      interest_rate,
      ticket_date,
      interest_payable,
      amount_with_interest,
      final_expiry_date,
      final_expiry_date_display,
      final_grant_date,
      difference_months
    };
  }




  

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }





  



 
  
 


  //-----------------------------------------------------------------------------------------


  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingcenter: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.primary.main,
            backgroundColor: lighten(theme.palette.primary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  }));



  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
    return (
      <Toolbar >
      <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Ticket List
          </Typography>
          <App_count/>
      </Toolbar>
    );
  };


  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  

 
  const MyEnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
   
    

    return (
      <Toolbar
        className={clsx(classes.root)}
      >
        
    <div style={{ width: '100%' }}>
      <Box display="flex" p={1} bgcolor="background.paper">

        <Box p={1} flexGrow={1} bgcolor="">
        <SelectListModal data={selected} />
        </Box>

        <Box p={1}  bgcolor="">
    
      
        {numSelected > 0 ? (
        <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >Total: {numSelected} 

        </Typography>
        ) : (
          <Typography>
          </Typography>
        )}

        </Box>

        <Box p={1} bgcolor="">
      
        {numSelected > 0 ? (
        <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >        <TotalModal data={selected} />

        </Typography>
        ) : (
          <Typography>
          </Typography>
        )}

      </Box>
      </Box>
      </div>
      <div>
      </div>
      </Toolbar>

    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };



//-----------------------------------------------------------------------------------------


const {  width } = useWindowDimensions();
var mywidth = (width-drawerWidth) * 99 /100 - 5


// drawer is close , 5 is for scroll
if (width <= 1350)
{ 
    mywidth = width * 99 /100 
 
}



// Change Table format
useEffect(() => { 
  if (width >820 )
  {setSw(false);  
  }      

  if (width <= 820 )
  {setSw(true);
    mywidth = width  * 100/100
  }

});


//----------------------------------------------------------------------------

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const headCells1 = [
  {
    id: "ticket_no",
    numeric: false,
    disablePadding: true,
    label: "Ticket No",
  },



  {
    id: "grant_amount",
    numeric: true,
    disablePadding: true,
    label: "Grant Amt",
  },
   
  {
    id: "grant_date",
    numeric: true,
    disablePadding: true,
    label: "Grant Date",
  },
 

 

  {
    id: "final_expiry_date_display",
    numeric: true,
    disablePadding: true,
    label: "Expiry Date",
  },


  
  

  {
    id: "amount_with_interest",
    numeric: true,
    disablePadding: false,
    label: "Interest Payable",
  },

  
  
  {
    id: "difference_months",
    numeric: true,
    disablePadding: true,
    label: "No of Mth",
  },


  


  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  

  
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];





const headCells2 = [
  
  {
    id: "ticket_no",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "amount_with_interest",
    numeric: true,
    disablePadding: false,
    label: "",
  },

   
];


headCells = headCells2;
if (sw)
{
headCells = headCells2;
}
else
{
headCells = headCells1;
};



function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    
  } = props;
  
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: "bold" }}
          >
           
            <TableSortLabel
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}



//----------------------------------------------------------------------------------------------



  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)

        
      );

    
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

 

  const isSelected = (ticket_no) => selected.indexOf(ticket_no) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });


//----------------------------------------------------------------------------

  shop = sessionStorage.getItem('shop')  
  var username = sessionStorage.getItem('username')  

  return (

    
    
    <div className={classes.root} style={{ marginTop: "15px" , width: mywidth}}>
      <Paper className={classes.paper}>
        
      {(() => {
          if (rows.length > 0  )
          {
          return <EnhancedTableToolbar numSelected={selected.length} />
          } else {
          return              
      }
      })()}

{(rows.length > 0  ) ?  
      
      <div>
      
      </div>
      
 : 
  

<Typography variant="h5" color="primary" sx={{fontWeight: 'bold'}}>{shop}. User: {username}. Time : {new Date().toLocaleString() + ""} . Record = 0. Your Tickets may be more than 7 mths or data is not ready. Tickets that are reported lost and police taken are not included for online renewal. If you just registered. Account activation within 24hrs. You can call the pawnshop if registration is not activated after 24hr. Thank you.</Typography>

 
}


      {(rows.length > 0  ) ? (
         
       <Table 
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
            id="mytable"
          >

          {(() => {
          if (rows.length > 0  )
          if (sw==false) {
          return <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
          />
          } else {
          return              
          }
          })()}


       <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;

          return (

          <TableRow
                      hover
                      onClick={
                        row.is_pending == undefined
                          ? (event) => handleClick(event, row)
                          : false
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row}
                      selected= {isItemSelected}
                      style =
                      
                      { index % 2? { background : "white" }:{ background : "#ebf0fa" }}
                      
                    >


          <TableCell padding="checkbox">
                        {row.is_pending == undefined ? (
                          <Checkbox
                            checked={isItemSelected}
                            color="primary"
                            inputProps={{ "aria-labelledby": labelId }}
                            
                          />
                        ) : null}
                        {/* <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        /> */}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        style={{ display: "none" }}
                      >
                        {row.id}
          </TableCell>
                     
                     
                     
          <TableCell component="th" scope="row" padding="none">
          {(() => {
          if (sw==false) {
          return <div>{row.ticket_no}</div>
          } else {
          return <div  style={{
            fontWeight: "bold",
          }}>
            Ticket no: {row.ticket_no}</div> 
          }
          })()}              
                  

       



          {/* Control the drawer close icon delpend of the width, sw mean small width        
          icon will only appear in small width                                      
          */}
          <div>
          {(() => {
          if (sw) {
          return  <div> 
               <div> Amount: {formatter.format(row.amount)}</div>
               <div> Grant Date: {row.final_grant_date}</div>
               <div> Expiry Date: {row.final_expiry_date_display}</div>
               <div> Interest: {formatter.format(row.interest_payable)}</div>
               <div> Description : {(row.item_description_1)}</div>
               <div> Alias Ticket No : {(row.alias_ticket_no)}</div>
             
    
               <div>
               {row.is_pending == undefined  ?
                         <span
                         style={{
                           color: "white",
                           // fontStyle: "italic",
                         }}
                       >
                         {""}
                         <Chip
                         size="medium"
                           label="Status: Open"
                           style={{
                             backgroundColor: "green",
                             fontWeight: "bold",
                             color:'white'
                           }}
                         />
                       </span>  
                       : 
                       <span
                       style={{
                         color: "black",
                         // fontStyle: "italic",
                       }}
                     >
                       {""}
                       <Chip
                          size="medium"                        
                         label="Processing Payment"
                         style={{
                           backgroundColor: "yellow",
                           fontWeight: "bold",
                         }}
                       />
                     </span>    
                 }
               </div>
               <div></div>
               </div>

               
          } else {
          return ;
          }
          })()}
          </div>
          </TableCell>



          <TableCell component="th" scope="row" padding="none" align="center">
          <div>
          {(() => {
          if (sw==false) {
          return  <div> 
          <div>{formatter.format(row.amount)}</div>
          </div>
          } else {
          return  <div> 
          </div>
          }
          })()}
          </div>
          </TableCell>



          {(() => {
          if (sw==false) {
          return <TableCell align="center" >
          {row.final_grant_date}
          </TableCell>
          
          } else {
          return  
          }
          })()}


          <TableCell component="th" scope="row" padding="none" align="center">
          <div>
          {(() => {
          if (sw==false) {
          return  <div> 
          <div> {row.final_expiry_date_display}</div>
          </div>
          } else {
          return  <div> 
          </div>
          }
          })()}
          </div>
          </TableCell>
  
                               
          {(sw==false) ? (
                 <TableCell align="center">
                 {formatter.format(row.interest_payable)}
                 </TableCell>
          ) : (
          <Typography>
          </Typography>
          )}




          <TableCell component="th" scope="row" padding="none" align="center">
          <div>
          {(() => {
          if (sw==false) {
          return <div> 
          <div> {row.difference_months}</div>
          </div>
          } else {
          return <div> 
          </div>
          }
          })()}
          </div>
          </TableCell>


          

       

        
          <TableCell component="th" scope="row" padding="none" align="left">
          <div>
          {(() => {
          if (sw==false) {
          return  <div> 
          <div>{(row.item_description_1)}</div>
          </div>
          } else {
          return  <div> 
          </div>
          }
          })()}
          </div>
          </TableCell>



          <TableCell  align="center">
          <div>
          {(() => {
          if (sw==false) {
          return  <div> 

          {row.is_pending == undefined  ?
                              <span
                              style={{
                                margincenter: "30px",
                                padding: "10px",
                                color: "white",
                                // fontStyle: "italic",
                              }}
                            >
                              {" "}
                              <Chip
                                label="Open"
                                style={{
                                  backgroundColor: "green",
                                  
                                  color:'white'
                                }}
                              />
                            </span>  
                            : 
                            <span
                            style={{
                              margincenter: "30px",
                              padding: "10px",
                              color: "black",
                              // fontStyle: "italic",
                            }}
                          >
                            {" "}
                            <Chip
                              label="Processing Payment"
                              style={{
                                backgroundColor: "yellow",
                                fontWeight: "bold",
                              }}
                            />
                          </span>    
                      }

               </div>
          } else {
          return  <div> 
          </div>
          }
          })()}
          </div>
          </TableCell>

                     
           </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
      
      ) : (
        <Typography>
        </Typography>
      )}
    

       {(rows.length > 0  ) ? (
        <TablePagination
        id="mytablepage"
        rowsPerPageOptions={[8]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
       ) : (
          <Typography>
          </Typography>
       )}
      

      </Paper>

      {/* button is here       /> */}

      {(rows.length > 0  ) ? (
        <MyEnhancedTableToolbar numSelected={selected.length} />
        ) : (
          <Typography>
          </Typography>
      )}
      

    
    
    </div>
  );


}
