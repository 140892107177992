import {FETCH_TICKET, FETCH_TODO} from '../reducers/types/ticketTypes';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jsonwebtoken'
import setAuthorizationToken from './authActions'
import { trackPromise } from 'react-promise-tracker'
import {logout} from './authActions';


const apiUrl = 'https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api'
const apiUrl_backup = 'https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api'


const config = setAuthorizationToken(localStorage.jwtToken)
const auth = jwt.decode(localStorage.jwtToken)


axios.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes("timeout") || message.includes("Network Error"))) {
  //    toast.error("err 2")
   //   return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        toast.error("Please wait")
        console.log("retry the request", config.url);
        resolve();
      }, config.retryDelay || 5000);
    });
    return delayRetryRequest.then(() => axios(config));
  });

  
export const getAllTicket = () => async dispatch =>{

    const {username} = auth

    const token = localStorage.jwtToken;
    var decodedToken=jwt.decode(token, {complete: true});
    var dateNow = new Date();

    try {
        const {mytoken} = localStorage.getItem('jwtToken')
        if (mytoken==='')
        {
          dispatch(logout());
        }
        if (mytoken===null)
        {
          dispatch(logout());
        }
        
    }
     catch (error){
    
        return "Internal server error"
    
        
    }

  //  toast.error("Connected "+username);    
  
  var test = 'y';
  var i, res;
      
  try {
  for (i = 0; i < 5; i++) {
    //  toast.success('Server...')
      res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000,  username,  test}))
      
      if(res.data.message === "testing : ") 
        {
        //   toast.error('Please wait')
            break;
        }    
      
      
  }
  } catch (error){}   
    


  var  shop = sessionStorage.getItem('shop')
  
  var  shopname = "CASHBOX"

  if (shop==='Thye Joo Pawnshop')
    {
    shopname = 'THYEJOO'  
    }
    if (shop==='Thye Eik Pawnshop')
      {
      shopname = 'THYEEIK'  
      }
//      sessionStorage.setItem('shop', 'Marsiling Pawnshop')  
if (shop==='Marsiling Pawnshop')
  {
  shopname = 'MARSILING'  
  }
     
  toast.success(shopname)

    try{
        
       shop =  sessionStorage.getItem('shop')
    //  toast.error(shop);

     //   const res1 =     trackPromise(axios.get(`https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/dra/getshopname?username=`+username, {username}, config))
      //  if(res1.data){
       // //toast.error("Read OK "+mytoken);
      //  //toast.error(res1.data.data);
  //    var shop = res1.data.data
  //    sessionstorage.setItem('shop',shop)
   //   localstorage.setItem('shop',shop)
  //    sessionStorage.setItem('shop', shop)  
  //    localStorage.setItem('shop', shop)  
    
    //  toast.error(shop);
      //  }
      //  else{ 
      //  }

        var encryptedStr = localStorage.getItem('data.orderIDInLocalStorage' );
    
            
        try {
        } catch (error){

          res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000,  username,  test}))
        }


        
        try {
        } catch (error){

          res = await trackPromise(axios.get(`https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/dra/ticket?username=`+username, config))
          res = await trackPromise(axios.get(`https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/dra/ticket?username=`+username, config))
        }

        try{
        

    

            try{
        //        const res = await trackPromise(axios.post(`https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api/ticket`,{username}, config))
                
        
        
        var res = await trackPromise(axios.get(`https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/dra/ticket?username=`+username, config))
        
            //    res = await trackPromise(axios.get(`https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/dra/ticket?username=`+username, config))
        
                sessionStorage.setItem('username', username );
              
                if(res.data.invalid_token  ){
                    console.log(res.data)
                    localStorage.removeItem('jwtToken')
                    window.location.replace("/login");
        
                }
                else if(res.data.data == "No Data Found"){
                    toast.error(res.data.data);
                //    return false
        
                }
               
                dispatch({
                    type: FETCH_TICKET,
                    payload: res.data
                 });

              //   toast.error(username);
                 return "200k"
                } catch (error){
        
               //     return "Internal server error"
                }
        
    
    
            } catch (error){
    
              // sessionStorage.setItem('Logout', 'done');
           //    localStorage.removeItem('jwtToken')
             //  dispatch(logout())
            }
         
    
     
    

        

        try{
           //  res = await trackPromise(axios.post(`${apiUrl}/ticket`,{username}, config))
             res = await trackPromise(axios.get(`https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/dra/ticket?username=`+username, config))
        
            sessionStorage.setItem('username', username );
          
            if(res.data.invalid_token  ){
                console.log(res.data)
                localStorage.removeItem('jwtToken')
                window.location.replace("/login");
    
            }
            else if(res.data.data == "No Data Found"){
              //  //toast.error(res.data.data);
                return false
    
            }
           
            dispatch({
                type: FETCH_TICKET,
                payload: res.data
             });
            } catch (error){
    
                
                
                
                toast.success("Retrieving Tickets : Ref-"+encryptedStr);



                  
        const res11 =     trackPromise(axios.get(`https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/dra/getshopname?username=`+username, {username}, config))
        if(res11.data){
       // //toast.error("Read OK "+mytoken);
      //  //toast.error(res1.data.data);
      var shop11 = res11.data.data
  //    sessionstorage.setItem('shop',shop)
   //   localstorage.setItem('shop',shop)
      sessionStorage.setItem('shop', shop11)  
      localStorage.setItem('shop', shop11)  
    
  //    //toast.error(shop);
        }
        else{ 
        }

        var encryptedStr = localStorage.getItem('data.orderIDInLocalStorage' );
    
        toast.success("Retrieving Tickets : Ref-"+encryptedStr);

        try{
        //    const res = await trackPromise(axios.post(`${apiUrl_backup}/ticket`,{username}, config))
            res = await trackPromise(axios.get(`https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api/dra/ticket?username=`+username, config))
        
            sessionStorage.setItem('username', username );
          
            if(res.data.invalid_token  ){
                console.log(res.data)
                localStorage.removeItem('jwtToken')
                window.location.replace("/login");
    
            }
            else if(res.data.data == "No Data Found"){
              //  //toast.error(res.data.data);
                return false
    
            }
           
            dispatch({
                type: FETCH_TICKET,
                payload: res.data
             });
            } catch (error){
    
                return "Internal server error"
            }
    

                return "Internal server error"
            }
    


        } catch (error){

      //  //toast.error("Read Error");
           sessionStorage.setItem('Logout', 'done');
           localStorage.removeItem('jwtToken')
           dispatch(logout())
        }
     

 

    
    
    
}

export const getAllTodoList = () => async dispatch =>{

    const {username} = auth

    toast.success("Retrieving Data");
        

    try{
        const res = await trackPromise(axios.post(`${apiUrl}/ticket`,{username}, config))

        if(res.data.invalid_token  ){
            console.log(res.data)
            window.location.replace("/login");

        }
        else if(res.data.data == "No Data Found"){
     //       //toast.error(res.data.data);
            return false

        }
       
        dispatch({
            type: FETCH_TODO,
            payload: res.data
         });
        } catch (error){

            return "Internal server error"
        }


}