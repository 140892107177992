import React from 'react';
import Box from "@material-ui/core/Box";

export default function TotalPaypal(props) {
  
  
  var total_price = 0;
  var subtotal_price = 0;
    for (let i = 0; i < props.data.length; i++) {
    let data = props.data[i];
    subtotal_price = subtotal_price + data.interest_payable;
  }
  var gateway_price = 0.00;
  var num = Number((gateway_price).toFixed(3)) // round up
  
  var adjust = 0.0030
  if (subtotal_price <= 30 ) 
    {adjust = 0.0000}
  
 
  var admin_price = Math.round(adjust*1000 + (num + Number.EPSILON) * 1000) / 1000
  total_price = subtotal_price + admin_price

  subtotal_price=sessionStorage.getItem('subtotal_price')  
  total_price=sessionStorage.getItem('subtotal_price')  
          
  

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });


  return (
  

  <div>
  
  
 
          
  <Box display="flex"  bgcolor="background.paper"
  alignItems="center"
  justifyContent="center"
  >
        
        <Box >
          
        <div style={{textAlign: 'right'}}><strong>Interest</strong> {}</div>
        <div style={{textAlign: 'right'}}><strong>Gateway Fee</strong> {}</div>
        <div style={{textAlign: 'right'}}><strong>Payment Total</strong> {}</div>
        
        </Box>
       
        <Box >
        <div style={{textAlign: 'right',marginLeft: '10px' }}><strong>:</strong> {}</div>
        <div style={{textAlign: 'right'}}><strong>:</strong> {}</div>
        <div style={{textAlign: 'right'}}><strong>:</strong> {}</div>
        </Box>
       
        <Box >
      
        <div style={{textAlign: 'right',marginLeft: '10px'}}> <strong>{formatter.format(subtotal_price)}</strong></div>
          <div style={{textAlign: 'right'}}> <strong>{formatter.format(admin_price)}</strong></div>
          <div style={{textAlign: 'right'}}> <strong>{formatter.format(total_price)}</strong></div>
      
        </Box>
      </Box>

  
  </div>
  
  
  );

  
}
 