import React, { useState, useEffect,useRef } from "react";
import logo from "../logo16n.png";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {login} from '../../actions/authActions';
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom';
import history from '../../history';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios';
import styles from './OApp.module.css';
import {  toast } from 'react-toastify';
import Spinner from '../layout/Spinner';
import {logout} from '../../actions/authActions';
import { Divider } from '@material-ui/core';




function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {

    let active = true;

    const image = new Image();
    image.src = {logo};
    
    image.onload = () => {
           if (!active) {
              return;
           }
            setLoaded('loaded');
         };
    

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}



function dosomething() {
}

// ==========================================================================================================
// URL and authorization header
// ==========================================================================================================
const apiUrl = 'https://pdmsonlinecashboxsoutheast.azurewebsites.net/online-pdms-cashbox/public/api'

function setAuthorizationToken (token){

  
  if(token){
        return {headers:{
            'Authorization': `Bearer ${token}`
        }}
    }
    else {
        return false    
    }


}
const config = setAuthorizationToken("MiA4MzA0In0.Lbs5QaXwinnwsKwpfy3APkO3poUjBljVOOcIk6-XUIQTEST")



// ==========================================================================================================








 function LoginPageTCA(  { props } ) {


  const dispatch = useDispatch()



  const [form, setForm] = useState({
    username: "",
    password: ""
    
  });
  const {  width } = useWindowDimensions();


  const [errorText, setErrorText] = useState({ error: false, error_text: "" });
  const [isAuthenticate, setIsAuthenticate] = useState(false);

  const authData = useSelector(state => state.auth.login)
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)

  const [Loading, setLoading] = useState(false);

  const [showResults, setShowResults] = React.useState(false)
  
  var email_message;
  
  const useImageLoaded = () => {
    const [loaded, setLoaded] = useState(false)
    const ref = useRef()
  
    const onLoad = () => {
      setLoaded(true)
    }
  
    useEffect(() => {
      if (ref.current && ref.current.complete) {
        onLoad()
      }
    })
  
    return [ref, loaded, onLoad]
  }
  
  const [ref, loaded, onLoad] = useImageLoaded()
  

  useEffect(() => {


  
  if(authData.message == "Unauthorized"){
    setErrorText({ error_text: "Incorrect Password Or Username", error: true });


  }
  else if(authData.message == "user is blocked"){
    setErrorText({ error_text: "User is Blocked", error: true });

  }
  else if(authData.message == "User is log in in other device please log out it first!."){
    setErrorText({ error_text: "User is log in on other device please log out it first!.", error: true });

  }
  else if(authData.message == "Credentials Correct"){
    
    var now = new Date();
    var minutes = 5;
    now.setTime(now.getTime() + (minutes * 60 * 1000));
    document.cookie = `otp_number=${authData.number}; expires= ${now.toUTCString()}`


    setIsAuthenticate(true);
  }
  
  }, [authData]);

  function handleChange(event) {

    sessionStorage.setItem('shoptc', event.target.value)  
    localStorage.setItem('shoptc', event.target.value)  
    
    const value = event.target.value;

    setForm({ ...form, [event.target.name]: value });


    


  }


  function handleChangeShop(event) {

    alert('Change Shopname');
    const value = event.target.value;

    setForm({ ...form, [event.target.name]: value });
  }

  function callapi()
  {

    if (form.username == "")
    {
    }
    else
    {
     
  //  //toast.error('Your Request is submitted.');
  //  //toast.error('The Password will be reset within 24hrs.');
    
    var username = form.username;
    var password = form.password;
    
    //axios.post(`${apiUrl}/dra/forgotpassword`, config)
    //axios.post(`${apiUrl}/dra/forgotpassword`, {username, password}, config )
    axios.post(`${apiUrl}/dra/forgotpassword`, {username, password}, config )
    .then(response => {
      console.log('-------------------------------------------------------------------DRANIX API CALL ONCE');
      console.log(response.data);
      
      var dummyString = response.data.message;
      email_message = dummyString.split("@").pop("*");
      email_message = '********@'+ email_message
      setLoading(false);
           
    //  alert('Your Request is submitted. The Password will be reset within 24hrs. Our staff will contact you.')  // onlinePDMS API test
      doOK();

   
    })
    .catch(error => {
      console.log('"API CALL fail');
      console.log(error);
  
      setLoading(false);
      
      alert('Your Request is not successuful. You may have key in the wrong username')  // onlinePDMS API test
    
    });

  }
  
  //toast.error('We are processing your request.');  
  
  

  }


  function doOK() {

    if (width < 600)
  
    {
    confirmAlert({
      customUI: ({ onClose }) => {
    return (
  
    <div>
    <h1>Message</h1>
        
        <p>We have send a password reset link to your email with {email_message}. If you don't see the email, please check you spam folder.</p>
        
        <button class={styles.button} 
              onClick={() => {
                window.location.replace('/');
                onClose();
                dispatch(logout());

              }}
            
              >
                OK</button>
                
        
        </div>
        );
      }
     });  
  
    }
    else
   
    confirmAlert({
      customUI: ({ onClose }) => {
    return (
  
   
    <div class={styles.body}>
    <h1>Message</h1>
        
    <p>We have send a password reset link to your email with {email_message} . If you don't see the email, please check you spam folder.</p>
        
    
    <button class={styles.button} 
          onClick={() => {
            window.location.replace('/');
            onClose();
            dispatch(logout());

          }}
        
          >
            OK</button>
            
         
  
        </div>
        );
      }
     });  
  
 //       alert('Your Request is submitted. The Password will be reset within 24hrs. Our staff will contact you.')  // onlinePDMS API test
    }
  
  
  function doforgot() {

   
    if (form.username == "")
    {
      alert('No Username');
    }
  

    else
    {

      if (width < 600)

      {
      confirmAlert({
        customUI: ({ onClose }) => {
      return (
    
      <div>
      <h1>Confirm</h1>
      <p>Hi {form.username}.</p>
      <p>Do you want to reset your password ?</p>
      
      <button class={styles.button} 
            onClick={() => {
              setLoading(true);
             
              callapi();
              onClose();
            }}
          
            >
              Yes</button>
              <button class={styles.button}
            onClick={() => {
              dosomething();
              onClose();
            }}
            >
            No
            </button>
           
    
          </div>
          );
        }
       });  

      }
      else
      {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            
          <div class={styles.body}>

           
            <h1>Forgot Password</h1>
            <p>Hi {form.username}. Do you want to reset your password ?</p>
          

            <button class={styles.button} 
            onClick={() => {
              setLoading(true);
              onClose();
              callapi();
            }}
          
            >
              Yes</button>
              <button class={styles.button}
            onClick={() => {
              dosomething();
              onClose();
            }}
            >
            No
            </button>

     
          </div>
          );
        }
        });  
      
      
      }
      
    }


    
  }





  function submitForm() {
    
  //  sessionStorage.setItem('shop', 'Thye Joo Pawnshop')  
  //  localStorage.setItem('shop', 'Thye Joo Pawnshop')  

    var shopname = document.getElementById('shopname').value
   
      
    form.shopname= shopname

    localStorage.removeItem('shoptc')  
    
    if (shopname=='Thye Joo Pawnshop')
    {
      sessionStorage.setItem('shoptc', 'Thye Joo Pawnshop')  
      localStorage.setItem('shoptc', 'Thye Joo Pawnshop')  

      sessionStorage.setItem('shop', 'Thye Joo Pawnshop')  
      localStorage.setItem('shop', 'Thye Joo Pawnshop')  
     
    }
    

    if (shopname=='Sheng Cheong')
    {
      sessionStorage.setItem('shoptc', 'Sheng Cheong Pawnshop')  
      localStorage.setItem('shoptc', 'Sheng Cheong Pawnshop')  
     
      sessionStorage.setItem('shop', 'Sheng Cheong Pawnshop')  
      localStorage.setItem('shop', 'Sheng Cheong Pawnshop')  
     
    }
    if (shopname=='Cashbox West Coast')
    {
      sessionStorage.setItem('shoptc', 'Cashbox West Coast')  
      localStorage.setItem('shoptc', 'Cashbox West Coast')  
          
      sessionStorage.setItem('shop', 'Cashbox West Coast')  
      localStorage.setItem('shop', 'Cashbox West Coast')  
      
    }
    if (shopname=='Sembawang Teck Hing')
    {
      
      sessionStorage.setItem('shoptc', 'Sembawang Teck Hing')  
      localStorage.setItem('shoptc', 'Sembawang Teck Hing')  
      
 
      sessionStorage.setItem('shop', 'Sembawang Teck Hing')  
      localStorage.setItem('shop', 'Sembawang Teck Hing')  
      
    }
   
    var test =  localStorage.getItem('shoptc')  
    
    if (test == null)
    {
      alert ("Pawnshop Not Selected")
    }
    else
    {
     dispatch(login(form))
    }

 
     
    
  }



  const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    avatar: {
      margin: theme.spacing(2),
      height: theme.spacing(10),
      width: theme.spacing(10),
      
    },
    
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    }
  }));

  const classes = useStyles();


  if(isAuthenticated){
    history.push('/')
  }
  if(isAuthenticate){
    return <Redirect to='/otp' />;

  }

  //window.localStorage.clear(); //try this to clear all local storage  
 // sessionStorage.setItem('shop', 'Thye Joo Pawnshop')  
 // localStorage.setItem('shop', 'Thye Joo Pawnshop')  

  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: 'Sheng Cheong', year: 1 },
  { title: 'Cashbox West Coast', year: 2 },
  { title: 'Sembawang Teck Hing', year: 3 },
 // { title: 'Thye Joo Pawnshop', year: 4 },
  
];
 
var showHideDemo1 = 1

 
window.localStorage.clear(); //try this to clear all local storage  
sessionStorage.setItem('shop', 'TrustcareGroup')  
localStorage.setItem('shop', 'TrustcareGroup')  
   

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          
     
        <Avatar ref={ref} onLoad={onLoad} src={logo} alt="" className={classes.avatar}/>
      
          {!loaded ? <Spinner /> : 
            (<React.Fragment>

       
    
    


       
            <Typography component="h1" variant="h5">
            <div>Sheng Cheong/Cashbox West Coast/STH Group</div>
            <p></p>
      
            </Typography>
          
     
      
      

            <Grid container direction={"column"} spacing={1}>

            <Grid item>

      <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoFocus
            onChange={handleChange}
          />

</Grid>
</Grid>

<Grid container direction={"column"} spacing={2}>

<Grid item>
               
           <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              error={errorText.error}
              helperText={errorText.error_text}
              onChange={handleChange}
           
            />

</Grid>

<Grid item>

<div>
{showHideDemo1 && 
<Autocomplete
        variant="outlined"
        margin="normal"
      
      id="shopname"
      name="shopname"
          
      options={top100Films}
      getOptionLabel={(option) => option.title}
      fullWidth
      required
           
      renderInput={(params) => <TextField {...params}  label="Name of Pawnshop" variant="outlined" onChange={handleChangeShop}
      />}
      />  
 }

    </div>
   </Grid>

   </Grid>
         
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={submitForm}
            >
              Sign In
            </Button>


            <Grid container>
              <Grid item xs = {4}>
                <Link href="#" variant="body2" onClick={() => {
              doforgot();
              
              }}>


                  {"Forgot password?"}
                </Link>
              </Grid>
              <Grid item xs = {4} >
                   
              </Grid>
              <Grid item xs = {4}>
                <Link href="/register" variant="body2">
                  {"Sign Up an Account"}
                </Link>
              </Grid>
            </Grid>

 
          </React.Fragment>
            )
          }
           
        </div>
     
      </Container>
  );
}

export default LoginPageTCA;
